@import ../../../styles/helpers

.wrapper
    // padding: 10px 0px
    height: 0
    opacity: 0
    overflow: hidden
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out
    // margin-top: -24px
    // margin-bottom: -24px
    &.show
        height: 230px
        opacity: 1
        // margin-top: -24px
        // margin-bottom: -24px
    +m
        margin-top: 0px
        margin-bottom: 0px
        &.show
            margin-top: 10px
            height: 260px

.row
    display: flex
    justify-content: center
    align-items: center
    height: 150px
    overflow: hidden
    padding-top: 35px
    margin-bottom: 20px
    // margin-bottom: 30px
    +m
        display: inline-grid
        justify-content: center
        align-items: center
        width: 100%
        margin-bottom: 20px

.col
    width: calc( 100% / 3)
    text-align: center
    +m
        width: 100%
        margin-bottom: 30px
        &:nth-child(1),
        &:nth-child(3)
            order: 1

.label
    font-size: 16px

.supply
    color: $s4

.borrow
    color: $s3

.value
    color: $n1
    font-size: 25px

.decimal
    color: $n4

.section
    width: 100%
    margin-bottom: 50px
    svg
        fill: $n4
        margin-left: 5px
        margin-top: -3px
    +m
        margin-bottom: 20px

.item
    margin: 10px 0px
    color: $n4
    font-size: 16px
    display: flex
    .value
        font-size: 16px
    +m
        margin: 20px 0px

.limit
    justify-content: space-between

.safeLimit
    justify-content: flex-end
