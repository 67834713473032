@import ../../styles/helpers

.page
    // background-color: var(--token-30c5159a-0571-42a9-94be-0aeb3b7837ae, rgb(12, 5, 18))
    background-color: #000
    // mask: linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 74.3701%, rgba(0, 0, 0, 0.318) 91.8919%, rgba(0, 0, 0, 0) 100%)
    padding: 96px 0 0 300px
    +xl
        padding: 96px 0 0 96px
    +t
        overflow: hidden
    +m
        padding-left: 0
    &.expand
        +xl
            padding: 96px 0 0 300px
        +d
            padding: 96px 0 0 96px
        +m
            padding-left: 0

.sidebar
    +m
        transform: translateX(-100%)
        transition: transform .3s
        &.visible
            transform: translateX(0)

.inner
    display: flex
    flex-direction: column
    min-height: calc(100vh - 96px)
    padding: 40px
    padding-top: 0px
    padding-left: 0px
    +x
        padding: 32px 24px
    +m
        padding: 24px 16px

.container
    display: flex
    flex-direction: column
    flex-grow: 1
    width: 100%
    // max-width: 1200px
    margin: 0 auto
    &.wide
        max-width: 100%

.title
    margin-bottom: 18px
    transition: 0.2s
    svg
        fill: darkgray
        margin-top: -8px
        transition: 0.2s
    +m
        margin-bottom: 0px
    &:hover
        svg
            fill: white
        .titleText
            color: white

.titleText
    margin-left: 6px
    color: darkgray
    cursor: default
    transition: 0.2s
    &.visible
        display: inline
    +s
        display: none

.titleMobileText
    display: none
    +s
        display: inline

.titleContainer
    display: flex
    justify-content: space-between
    +m
        flex-direction: column

.netApyContainer
    +m
        display: flex
        margin-bottom: 20px
    +s
        flex-direction: column
        gap: 4px

.custom
    width: 32px
    margin-top: -4px
