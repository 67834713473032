@import ../../styles/helpers

.card
    height: 100%

.head
    +m
        display: block

.title
    +m
        margin: 0 0 16px

.nav
    display: flex
    margin-left: auto
    .button
        padding: 8px 16px
        border-radius: 8px
        +base1-s
        color: $n4
        transition: all .2s
        +m
            flex: 1
        &:hover,
        &.active
            color: $n7
            +dark
                color: $n1
        &.active
            background: $n3
            +dark
                background: $n6
        &:not(:last-child)
            margin-right: 4px
            +m
                margin-right: 0


.tooltip
    color: gray
    fill: gray
    margin-left: 5px
    margin-top: -2px

.wrapper
    height: 92%

.scroll
    height: 440px

.table
    display: table
    width: 100%

.foot
    margin-top: 24px
    display: flex
    justify-content: center
    color: $n4
    .loader
        transform: scale(.8)
        margin: 10px 18px 0 8px

.row
    display: table-row
    +m
        display: block
        &:not(:last-child)
            margin-bottom: 16px
            padding-bottom: 16px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6
    &:first-child
        position: sticky
        top: 0
        z-index: 10
        background-color: $n7
        .col
            padding-top: 16px
            padding-bottom: 16px
            font-size: 13px
            font-weight: 600
            color: $n4
    &:nth-child(2n)
        .col
            background: rgba($n3, .35)
            +dark
                background: rgba($n6, .3)
            +m
                background: transparent !important

.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 16px 12px
    text-align: left
    font-weight: bold
    color: $n7
    &:first-child
        padding-left: 0px
        white-space: nowrap
        color: $n4 !important
        +m
            padding-left: 12px
            color: $n1
    +m
        display: flex
        width: 100%
        padding-bottom: 0px
    +dark
        color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        top: 0
        right: 0
        height: 1px
        background: $n3
        +m
            display: none
        +dark
            background: $n6
    &:first-child
        border-radius: 8px 0 0 8px
        &:after
            left: 12px
    &:last-child
        border-radius: 0 8px 8px 0
        &:after
            right: 12px
    &:not(:first-child)
        padding-top: 20px
        +m
            padding-top: 16px
