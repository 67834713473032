@import ../../styles/helpers

.wrapper
    border: 1px solid $n3
    border-radius: 6px
    padding: 12px
    margin-bottom: 20px
    min-height: 60px
    +dark
        border-color: $n6

.row
    display: flex
    justify-content: space-between
    margin-bottom: 5px
    height: 24px

.myicon
    fill: $n4
    margin-right: -28px
    position: relative
    z-index: 1
    margin-top: -1px
    margin-left: 5px
    svg
        width: 24px
        height: 24px
        margin-left: -10px
        fill: $n4
        transform: scale(1.1)
        +dark
            fill: $n1

.customwise
    img
        transform: scale(0.9) !important
        margin-left: -4px
        margin-top: -2px

.textfield
    width: 100%
    background: none
    border: none
    box-shadow: none
    +title1-s
    color: $n7
    font-size: 21px
    font-weight: 400
    +dark
        color: $n1
    &::placeholder
        color: $shades3
        +dark
            color: $shades1

.token
    display: flex
    align-items: center
    margin-top: 1px
    .name
        font-size: 18px
    .currency
        width: 24px
        height: 24px
        margin-right: 12px
        border-radius: 50%
        background: $n3
        +dark
            background: $n6
        img
            transform: scale(1.1)

.reset
    width: 20px
    height: 20px
    border-radius: 50%
    background: $n3
    font-size: 0
    margin: 0 10px
    display: none
    &.active
        display: block
    svg
        fill: $n5
        transition: transform .2s
    +dark
        background: $n6
        margin-top: 7px
        margin-right: 20px
        svg
            fill: $n1

.preview
    color: $n4
    font-size: 14px
    font-weight: 500
    margin-top: 12px

.balance
    color: $n4
    font-size: 13px
    font-weight: 500
    display: flex
    margin-top: 20px
    align-items: center
    .max
        color: $n4
        font-weight: 600
        margin-left: 3px
        cursor: pointer
        font-size: 13px
        &:hover
            color: $n1

