@import ../../../styles/helpers

.panel
    margin-top: 0px
    display: flex
    justify-content: space-between
    align-items: center
    height: 77px
    background: #111
    margin-left: -24px
    margin-right: -24px
    padding-left: 24px
    padding-right: 24px
    position: absolute
    bottom: 0px
    width: 100%
    position: absolute
    backdrop-filter: blur(3px)
    background-color: rgba(12, 5, 18, 0.7)
    +t
        display: none

.inactive
    background-color: rgba(22, 22, 22, 0.7)



.loader
    margin-right: 25px
    margin-top: 10px

.info
    margin-right: 40px
    color: $n4
    display: flex
    justify-content: center
    align-content: center
    svg
        margin-right: 12px
        fill: $n4
    &:hover
        color: $n1
        cursor: pointer
        svg
            fill: $n1

.btns
    display: flex
    align-items: center

.button
    border-radius: 20px
    height: 40px
    display: none
    margin-bottom: 10px
    margin-top: 10px
    &:not(:last-child)
        margin-right: 8px