@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    +t
        position: relative
        display: block
    &:hover
        +rmin(1024)
            background: $n2
            +dark
                background: $n6
            .number,
            .number
                +dark
                    background: $n5
            .control
                visibility: visible
                opacity: 1
    &:not(:last-child)
        +t
            margin-bottom: 24px
            padding-bottom: 24px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6

.sender
    max-width: 510px

.col
    position: relative
    display: table-cell
    vertical-align: top
    padding: 16px 12px
    color: $n7
    +t
        position: static
        display: flex
        align-items: center
        padding: 0
    +dark
        color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 0px
        display: none
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:nth-child(4)
        +t
            display: none
    &:not(:first-child):not(:nth-child(2))
        white-space: nowrap
        padding-top: 22px
        +t
            padding-top: 0
    &:last-child
        text-align: center
        &:after
            right: 12px
    &:not(:first-child):not(:last-child)
        +t
            margin-bottom: 12px

.item
    display: flex
    align-items: center
    // cursor: pointer
    transition: color .2s
    +m
        align-items: stretch
    +dark
        color: $n1
    &:hover
        color: $p1

.preview
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 20px
    border-radius: 8px
    overflow: hidden
    +t
        width: 104px
    img
        width: 100%
        height: 100%
        object-fit: cover

.product
    max-width: 170px
    margin-bottom: 4px
    +t
        max-width: 100%
        margin-bottom: 8px
        padding-right: 42px
        +title2
.wrap
    +t
        display: flex
        align-items: center

.category
    +caption1
    color: $n4
    +t
        font-size: 15px
    +dark
        color: $shades1

.sales
    display: inline-flex
    align-items: center

.number,
.price
    display: inline-block
    padding: 0 8px
    border-radius: 6px
    background: $n3
    transition: background .2s
    +dark
        background: $n5
        color: $n1

.price
    display: none
    +t
        display: inline-block
        margin-right: 12px

.balance
    margin-left: 8px

.box
    display: flex
    align-items: center

.line
    position: relative
    flex-shrink: 0
    width: 50%
    height: 8px
    margin-left: 8px
    +d
        width: 36px
    +t
        width: 44px

.progress
    position: absolute
    top: 0
    left: 0
    bottom: 0
    border-radius: 2px

.status
    transition: background .2s

.control
    position: absolute
    left: 12px
    bottom: 16px
    visibility: hidden
    opacity: 0
    transition: all .2s
    +t
        top: 0
        left: auto
        right: 0
        bottom: auto
        visibility: visible
        opacity: 1

.label
    display: none
    +t
        display: block
        flex-shrink: 0
        width: 124px
        padding-right: 20px
        +caption1
        color: $n4

.holders
    display: flex
    gap: 10px
    align-items: center
    justify-content: flex-end
    width: 100%
    padding-right: 10px

.loader
    transform: scale(0.8)
    margin-right: 20px

.buttons
    margin-top: -7px
    height: 40px
    color: $n7
    &:first-child
        width: 165px
