@import ../../../../styles/helpers

.row
    display: table-row
    cursor: pointer
    transition: background .2s
    .borrow
        background: $s2
        color: $n7
        &:hover
            background: $s2
            filter: saturate(2)
        &.disabled
            filter: grayscale(1)
    +m
        display: block
        &:not(:last-child)
            margin-bottom: 16px
            padding-bottom: 16px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6
    &:hover
        .col
            &::after
                display: none
        .currency
            opacity: 1
            filter: saturate(1.5)
        +dark
            background: #444
        .borrow
            background: $s2
            filter: saturate(2)
    &.disabled
        filter: grayscale(1)

.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 16px 12px
    text-align: center
    +base2
    color: $n7
    +m
        display: flex
        width: 100%
        padding-bottom: 0px
    +dark
        color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        top: 0
        right: 0
        height: 1px
        background: $n3
        +m
            display: none
        +dark
            background: $n6
    &:first-child
        border-radius: 8px 0 0 8px
        &:after
            left: 12px
    &:last-child
        padding-right: 10px !important
        border-radius: 0 8px 8px 0
        &:after
            right: 12px
    &:not(:first-child)
        padding-top: 20px
        padding-right: 8px
        padding-left: 8px
        +m
            padding-top: 16px
    &:not(:last-child)
        padding-top: 20px
        +m
            padding-top: 16px

.token
    display: flex
    align-items: center
    +s
        justify-content: space-between
        width: 100%
        div > div
            text-align: right !important
    .description
        display: none
        font-size: 14px
        +m
            display: block
    .title
        font-size: 14px
        margin-left: 16px
        +m
            font-size: 12px
            color: #6F767E
            text-align: left
    .currency
        width: 32px
        height: 32px
        border-radius: 50%
        background: $n3
        opacity: 0.8
        +dark
            // background: $n6
            background: none
        +m
            width: 40px
            height: 40px

.buttons
    display: flex
    align-items: center
    gap: 10px
    +m
        width: 100%
    .button
        flex: 1 1

.infos
    margin-left: 0px
    margin-right: 0px
    padding: 0px
    max-width: 40px

.expand .infos
    display: none !important

.collateral
    display: flex
    align-items: center
    justify-content: center

.item
    display: flex
    justify-content: center
    width: 100%
    +m
        justify-content: space-between
    .label
        display: none
        +m
            display: block
