@import ../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1

.disabled
    opacity: .5
    pointer-events: none
    filter: grayscale(1)

.viewButton
    background-color: #5c9cde



.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 16px 12px
    text-align: center
    white-space: nowrap
    color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:nth-child(2)
        text-align: left
    &:first-child
        text-align: left
        border-radius: 8px 0 0 8px
        width: 200px
        &::after
            left: 12px
    &:last-child
        // width: 300px !important
        width: 200px !important
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
            width: 100% !important
        &:after
            right: 12px
    // &:nth-child(3)
    //     +m
    //         display: none
    +t
        display: flex
        width: 100% !important
        padding-bottom: 0px


    // &:not(:nth-child(2))
    //     +t
    //         display: none

.productItem
    display: flex
    align-items: center
    // cursor: pointer
    transition: color .2s
    +m
        align-items: stretch
    +dark
        color: $n1
    &:hover
        // color: $p1


.infos
    margin-left: 0px
    margin-right: 0px
    padding: 0px
    max-width: 40px


.buttons
    .button
        color: $n8
        width: 160px
        height: 40px
    +m
        width: 100%

.collateral
    display: flex
    align-items: center
    justify-content: center
    svg
        fill: $p2

.item
    display: flex
    justify-content: center
    width: 100%
    +m
        justify-content: space-between
    .label
        display: none
        +m
            display: block

    .apyValue
        display: flex
        align-items: center
        font-size: 14px !important
        gap: 4px

.apyValue
    display: flex
    align-items: center
    font-size: 14px !important
    gap: 4px

.modalTemp
    min-height: 100px

.details
    +t
        display: flex
        flex-direction: column
        justify-content: center

.product
    max-width: 170px
    margin-bottom: 4px
    font-weight: 700
    +t
        max-width: 100%
        margin-bottom: 8px
        // padding-right: 44px
        +title2
.link
    +caption1
    color: $shades1
    white-space: nowrap

.tooltip
    svg
        margin-top: -3px
        margin-left: 5px
        fill: $shades1

.openOuter
    max-width: 896px
    & > div
        +m
            padding: 26px 16px 16px