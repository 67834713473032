@import ../../../../styles/helpers

.item
    display: flex
    align-items: center

.link
    margin-top: 0px
    padding-top: 0px
    color: $shades1
    +t
        display: none

.preview
    background-color: $n2
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 20px
    border-radius: 8px
    overflow: hidden
    img
        width: 100%
        height: 100%
        object-fit: cover

.product
    margin-bottom: 0px
    line-height: 20px !important
    +title1-s

.category
    +caption1
    color: $shades1

.new,
.progress
    display: inline-block
    padding: 2px 8px
    border-radius: 6px
    white-space: nowrap

.new
    background: $s4

.progress
    background: $s2

