@import ../../../../styles/helpers

.parameterContainer
    margin-right: 5px

.parameter
    display: flex
    align-items: center
    padding: 15px 0
    border-bottom: 1px solid rgba($shades4, .1)
    +dark
        border-color: rgba($shades2, .15)

.hasData
    // cursor: pointer
    .title
        color: $n1

.big
    font-size: 14px

.title
    margin-right: auto
    padding-right: 12px
    color: $n4
    flex-shrink: 0
    span
        margin-right: 5px

.tooltip
    position: relative
    top: -1px
    svg
        fill: $n4

.yes,
.no
    display: inline-block
    padding: 2px 8px
    border-radius: 6px
    white-space: nowrap
    +dark
        color: $n7

.yes
    background: $s4

.no
    background: $s1

.content
    text-align: right

.hidden
    display: none

.itemDescription
    color: $n4
    font-weight: 500
    text-align: right

.price,
.content
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 5px
    text-overflow: ellipsis
    justify-content: flex-end