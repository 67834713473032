@import ../../../../styles/helpers

.riskLevel
    display: flex
    justify-content: space-between

.label
    display: flex
    justify-content: center
    align-items: center
    gap: 6px
    div
        font-size: 14px

