@import ../../../styles/helpers

.card
    padding-bottom: 24px
    background: $n1
    border-radius: 8px
    height: 100%
    overflow: hidden
    +dark
        background: $n7
    +m
        height: 450px

.wrapper
    height: 95%

.scroll
    height: 440px

.table
    display: table
    width: 100%

.foot
    margin-top: 24px
    display: flex
    justify-content: center
    color: $n4
    .loader
        transform: scale(.8)
        margin: 10px 18px 0 8px

.row
    display: table-row
    +m
        display: block
        &:not(:last-child)
            margin-bottom: 16px
            padding-bottom: 16px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6
    &:first-child
        position: sticky
        top: 0
        z-index: 10
        background-color: $n7
        .col
            padding-top: 16px
            padding-bottom: 16px
            +caption2
            font-weight: 500
            color: $n4
    &:nth-child(2n)
        .col
            background: rgba($n3, .35)
            +dark
                background: rgba($n6, .3)
            +m
                background: transparent !important

.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 16px 12px
    text-align: center
    +base2
    color: $n7
    &:first-child
        padding-left: 0px
        white-space: nowrap
        color: $n4 !important
        +m
            padding-left: 12px
            color: $n1
    +m
        display: flex
        width: 100%
        padding-bottom: 0px
    +dark
        color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        top: 0
        right: 0
        height: 1px
        background: $n3
        +m
            display: none
        +dark
            background: $n6
    &:first-child
        border-radius: 8px 0 0 8px
        &:after
            left: 12px
    &:last-child
        border-radius: 0 8px 8px 0
        &:after
            right: 12px
    &:not(:first-child)
        padding-top: 20px
        +m
            padding-top: 16px

.head
    text-align: center
    +m
        display: none !important

@keyframes colorSwitch
    0%
        fill: $n1
    50%
        fill: $shades1
    100%
        fill: $n1


.refreshBtn
    flex-shrink: 0
    width: 40px
    height: 40px
    border-radius: 8px
    cursor: pointer
    &:disabled
        pointer-events: none
    svg
        fill: $shades1
        transition: fill .2s
        transform-origin: 42% 42%
    &:hover
        svg
            fill: $n7
            +dark
                fill: $n1
    &.active
        svg
            animation: colorSwitch 1s linear infinite
