@import ../../../../styles/helpers

.title
    margin-bottom: 16px

.row
    display: flex
    +t
        display: block
.scroll
    height: 517px


.spec
    list-style-type: disc
    margin-left: 16px
    color: #bbb
    cursor: default
    &:hover
        color: $n1


.col
    flex: 0 0 50%
    &:first-child
        padding: 24px
        border-radius: 12px
        background: rgba($n2, .5)
        +t
            margin-bottom: 48px
        +m
            margin-bottom: 32px
            padding: 16px
        +dark
            background: $n6
    &:nth-child(2)
        padding: 30px 0 0 24px
        padding-top: 28px
        +t
            padding: 0

.bigger
    font-size: 24px
    font-weight: 500
    margin-bottom: 24px
    +m
        font-size: 20px
        margin-bottom: 16px

.product
    margin-bottom: 16px
    filter: brightness(0.8) contrast(1.4)
    img
        border: none !important

.btnsWrapper
    border-top: 1px solid $n3
    margin: 9px -4px 0
    padding-top: 15px
    justify-content: center
    +dark
        border-color: $n6

.transaction
    width: calc(100% - 8px)
    margin: 0 4px 5px 4px
    margin-bottom: 10px
    margin-top: 12px
    height: 40px

.btns
    .loader
        transform: scale(.8)
        margin: 0 18px 0 8px
        filter: invert(1)
    .button
        width: calc(50% - 8px)
        margin: 0 4px
        height: 40px
        svg
            fill: $n7
        +m
            padding: 0 8px
        &.disabled
            filter: grayscale(1)
        &.full
            color: $n7
            width: 100% !important

.box
    &:not(:last-child)
        border-bottom: 1px solid rgba($shades4, .1)

.info
    margin-bottom: 10px
    +title1-s

.text
    z-index: 1
    position: relative
    margin-bottom: 12px
    font-weight: 500
    padding-left: 10px
    padding-right: 10px
    line-height: 24px
    color: $n5
    +dark
        color: $n3

.wrapper
    border: 1px solid $n3
    border-radius: 6px
    padding: 12px
    margin-bottom: 20px
    // font-weight: 500
    +dark
        border-color: $n6

.wrapperTitle
    color: $n4
    font-weight: 400
    font-size: 14px
    margin-bottom: 4px
    display: flex
    justify-content: space-between
    svg
        fill: $n4
        margin-left: 5px

.successWrapper
    text-align: center
    position: relative
    min-height: 250px

.acceptConditionsField
    display: flex
    justify-content: space-between
    align-items: center

