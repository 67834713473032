@import ../../../../styles/helpers

.head
    margin-bottom: 24px
    border-bottom: 1px solid $n3
    +dark
        border-color: $n6

.title
    margin-bottom: 16px
    color: $n1

.button
    width: 100%
    height: 40px !important
    color: $n7
    &.invalidReferrer
        filter: grayscale(1)

.foot
    z-index: 2
    margin-top: 14px
    padding-top: 0px
    border-top: 1px solid $n3
    +dark
        border-color: $n6
    .button
        width: 100%
        margin-top: 10px
        display: inline-flex
    .loader
        transform: scale(.8)
        margin: 0 18px 0 8px
    &.invalidReferrer
        margin-top: 0

.successWrapper
    z-index: 1
    text-align: center
    .icon
        margin-top: 30px
        display: flex
        justify-content: center
        svg
            fill: $p2
    .text
        margin-top: 24px
        font-size: 16px
        text-overflow: ellipsis
        overflow: hidden

.referrerAddress
    padding: 2px 8px
    border-radius: 6px
    cursor: pointer
    background-color: rgba(131, 191, 110, 0.15)
    +dark
        color: $p2

.comments
    transition: opacity 0.3s ease
    &.disabled
        opacity: 0.5

.dark-mode
    .button-stroke
        height: 40px !important

.loader
    filter: invert(1)
