@import ../../../styles/helpers

.wrapper
    margin: 0 -12px
    overflow: auto

.table
    display: table
    width: 100%

.head
    text-align: center
    +m
        display: none !important

.row
    display: table-row
    +m
        margin-bottom: 24px

.pointA
    width: 50px

.hodler
    position: absolute
    right: 25px
    top: 15px
    display: flex

.pointB
    width: 35px
    height: 35px
    margin-top: 8px
    border-width: 2px
    border-radius: 23px
    border-color: #1ccce4
    border-style: solid

.col
    display: table-cell
    padding: 4px 6px 16px
    white-space: nowrap
    +caption2
    font-weight: 600
    vertical-align: bottom
    font-size: 14px
    // line-height: 24px
    color: $n4
    .tooltip
        fill: $n4
        margin-left: 5px
        margin-top: -1px
        width: 100%
        // position: absolute

.tilt
    margin-left: -12px
    margin-top: -12px
    color: white

.foot
    margin-top: 12px
    text-align: center
    +m
        margin-top: 32px
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

