@import ../../../styles/helpers

.form
    width: 100%
    margin-bottom: 24px

.item
    &:not(:last-child)
        margin-bottom: 24px

.label
    margin-bottom: 16px
    font-size: 16px
    color: $n1

.dropdownHead
    +dark
        color: $n1

.checkbox
    &:not(:last-child)
        margin-bottom: 12px
        font-size: 14px

.box
    position: relative
    .dropdownHead
        padding-left: 48px
    svg
        position: absolute
        left: 12px
        bottom: 12px
        z-index: 10
        pointer-events: none
        fill: $s5

.btns
    display: flex
    justify-content: flex-start
    margin-top: 20px
    .button
        height: 40px
        width: 50%
        &:not(:last-child)
            margin-right: 12px