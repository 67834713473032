@import ../../styles/helpers

.item
    display: flex
    justify-content: space-between
    width: 100%
    font-size: 14px
    .label
        font-weight: 400
        svg
            fill: #6F767E
            margin-left: 5px
            margin-top: -2px
    .value
        font-weight: 500
    .cost
        svg
            fill: #fff