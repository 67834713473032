@import ../../styles/helpers

.line
    position: relative
    flex-shrink: 0
    width: 100%
    border-radius: 4px
    overflow: hidden

@keyframes progress-active
    0%
        opacity: .7
        width: 0
    100%
        opacity: 0
        width: 100%

.progress
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 100px
    &:after
        content: ""
        opacity: 0
        width: 100px
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: #fff !important
        animation: progress-active 2s ease infinite

.limit
    position: absolute
    top: 0
    bottom: 0

