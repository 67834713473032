@import ../../../styles/helpers

.title
    margin-bottom: 16px
    color: $n4

.button
    width: 100%
    height: 40px !important
    margin-top: 12px
    .loader
        transform: scale(.8)
        margin-right: 18px

.fieldset
    margin-bottom: 1px
    .dropdown,
    .field
        margin-bottom: 12px

.box
    &:not(:last-child)
        margin-bottom: 32px

.label
    margin-bottom: 16px
    +caption1
    font-weight: 500
    color: $n4

.line
    display: flex
    align-items: center

.list
    display: flex
    margin-right: auto

.avatar
    position: relative
    width: 48px
    height: 48px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover
    &:not(:last-child)
        margin-right: 24px

.icon
    position: absolute
    right: -4px
    bottom: -4px
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    border-radius: 50%
    border: 2px solid $n
    background: $p1
    +dark
        border-color: $n7
    svg
        fill: $n1

.referrerAddress
    padding: 0 6px
    border-radius: 6px
    cursor: pointer
    background-color: $s4
    +dark
        color: $n7

.comments
    transition: opacity 0.3s ease
    &.disabled
        opacity: 0.5

.dark-mode .button-stroke
    height: 40px !important
