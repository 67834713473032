@import ../../../../styles/helpers

.title
    margin-bottom: 16px

.row
    display: flex
    +t
        display: block
.scroll
    height: 517px


.token
    background: red

.quicks
    display: flex
    margin-top: -10px
    justify-content: space-between
    margin-bottom: 16px
    &
        div
            filter: grayscale(1)
            &:hover
                cursor: pointer
                filter: none

    +t
        flex-wrap: wrap
        margin-bottom: 16px
    +m
        margin-bottom: 12px

.col
    flex: 0 0 50%
    &:first-child
        padding: 24px
        border-radius: 12px
        background: rgba($n2, .5)
        +t
            margin-bottom: 48px
        +m
            margin-bottom: 32px
            padding: 16px
        +dark
            background: $n6
    &:nth-child(2)
        padding: 30px 0 0 24px
        padding-top: 28px
        +t
            padding: 0

.product
    margin-bottom: 16px

.btnsWrapper
    border-top: 1px solid $n3
    margin: 9px -4px 0
    padding-top: 15px
    justify-content: center
    +dark
        border-color: $n6
.riskLevel
    display: flex
    justify-content: space-between

.labelDisabled
    filter: grayscale(1)
    color: $n4

.transaction
    width: calc(100% - 8px)
    margin: 0 4px 5px 4px
    margin-bottom: 10px
    margin-top: 12px
    height: 40px
.btns
    // display: flex
    .loader
        transform: scale(.8)
        margin: 0 18px 0 8px
        filter: invert(1)
    .button
        // flex: 0 0 calc(50% - 8px)
        width: calc(50% - 8px)
        height: 40px
        margin: 0 4px
        +m
            padding: 0 8px
        &.disabled
            filter: grayscale(1)
        &.hide
            display: none
        &.full
            width: 100% !important
            color: $n7 !important
            svg
                filter: invert(1)
        // &:first-child
        //     +dark
        //         background: $n8
        //         box-shadow: inset 0 0 0 2px $n8
        //         &:hover
        //             box-shadow: inset 0 0 0 2px $n1

.box
    &:not(:last-child)
        // margin-bottom: 24px
        // padding-bottom: 15px
        border-bottom: 1px solid rgba($shades4, .1)

.info
    margin-bottom: 10px
    +title1-s

.list
    li
        position: relative
        padding-left: 36px
        font-weight: 500
        color: $n5
        +dark
            color: $n3
        &:before
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 24px
            height: 24px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%23b5e4ca'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        &:not(:last-child)
            margin-bottom: 12px
            padding-bottom: 12px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6

.text
    z-index: 1
    position: relative
    margin-bottom: 12px
    font-weight: 500
    padding-left: 10px
    padding-right: 10px
    line-height: 24px
    color: $n5
    +dark
        color: $n3

.user
    display: flex
    align-items: center

.avatar
    flex-shrink: 0
    width: 32px
    height: 32px
    margin-right: 12px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.editor
    margin-top: 32px
    +m
        margin-top: 24px

.wrapper2
    border-bottom: 1px solid $n3
    padding-bottom: 10px
    margin-bottom: 15px
    margin-top: 0px
    +dark
        border-color: $n6

.wrapperTitle
    color: $n4
    font-weight: 400
    font-size: 14px
    margin-bottom: 4px
    display: flex
    justify-content: space-between
    svg
        fill: $n4
        margin-left: 5px

.wrapperRow
    display: flex
    justify-content: space-between
    margin-bottom: 0px
.wrapperItemMB
    margin-bottom: 10px

.wrapperItem
    display: flex
    justify-content: space-between
    width: 100%
    font-size: 14px
    align-items: center
    .label
        font-weight: 400
        svg
            fill: #6F767E
            margin-left: 5px
            margin-top: -2px
    .value
        font-weight: 500
        line-height: 0px

.range
    padding-top: 15px
    padding-bottom: 10px

.error
    color: $p3
    text-align: center
    margin-bottom: 10px
    min-height: 24px

.successWrapper
    // margin-top: 30px
    text-align: center
    position: relative
    min-height: 370px
    .icon
        margin-top: 30px
        display: flex
        justify-content: center
        svg
            fill: $p2
    .text
        margin-top: 24px
        font-size: 16px

.imageHolder
    min-height: 250px
