@import ../../../../styles/helpers

.status
    font-size: 14px
    line-height: 28px
    font-weight: 600

.user
    color: $n1

.currency
    width: 32px
    height: 32px
    border-radius: 50%
    background: $n3
    display: flex
    justify-content: center
    +dark
        // background: $n6
        background: none
    +m
        width: 20px
        height: 20px

.item
    display: flex
    justify-content: center
    width: 100%
    +m
        justify-content: space-between
    .label
        display: none
        +m
            display: block

.buttons
    display: flex
    align-items: center
    gap: 10px
    +m
        width: 100%
    .button
        flex: 1 1

.token
    display: flex
    .text
        margin-left: 7px
