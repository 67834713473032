@import ../../../../styles/helpers

.previewContainer
    position: relative

.preview
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 20px
    border-radius: 8px
    overflow: hidden
    +t
        width: 104px
        height: 116px
    img
        width: 100%
        height: 100%
        object-fit: cover
        background: white
        padding: 0px

.badge
    width: 30px
    position: absolute
    border-radius: 50%
    border: 2px solid #1A1D1F
    right: 8px
    bottom: -8px
    width: 36px
