@import ../../../../../styles/helpers

.title
    margin-bottom: 16px

.wrapperTitle
    color: $n4
    font-weight: 400
    font-size: 14px
    margin-bottom: 4px
    svg
        fill: $n4
        margin-left: 5px

.button
    background: $s3
    color: $n7
    svg
        fill: $n7

.wrapper
    border: 1px solid $n3
    border-radius: 6px
    padding: 12px
    margin-bottom: 20px
    +dark
        border-color: $n6

.row
    display: flex
    justify-content: space-between
    margin-bottom: 5px

.item
    display: flex
    justify-content: space-between
    width: 100%
    font-size: 14px
    .label
        font-weight: 400
    .value
        font-weight: 500

.foot
    margin-top: 14px
    padding-top: 0px
    border-top: 1px solid $n3
    +dark
        border-color: $n6
    .button
        width: 100%
        margin-top: 10px
        display: inline-flex
        &:hover
            background: $s3
            filter: saturate(2)
    .button.button-transparent
        background: none !important
    .loader
        filter: invert(1)
        transform: scale(.77)
        margin: 0 18px 0 8px

.mbAPY
    margin-bottom: 0px

.error
    color: $p3
    text-align: center
    margin-bottom: 6px
    margin-top: 12px

.warning
    color: $s5
    text-align: center
    margin-bottom: 10px

.successWrapper
    // margin-top: 30px
    text-align: center
    .icon
        margin-top: 30px
        display: flex
        justify-content: center
        svg
            fill: $p2
    .text
        margin-top: 24px
        font-size: 16px

.failed
    svg
        fill: $p3 !important

.borrowLine
    padding-top: 10px
    padding-bottom: 5px

.button.button-transparent
    background: none !important

.dark-mode .button.button-stroke
    background: none !important

.dark-mode.disabled
.bgtransparent,
.button-transparent
    background: none !important

.icongo
    svg
        fill: $n4
        margin-left: 5px
        margin-right: 5px
.stroke
    background: none !important
    color: $s3
    &:hover
        background: $s3
        color: $n7