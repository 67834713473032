@import ../../../../../styles/helpers

.title
    margin-bottom: 16px

.wrapperTitle
    color: $n4
    font-weight: 400
    font-size: 14px
    margin-bottom: 4px
    display: flex
    justify-content: space-between
    svg
        fill: $n4
        margin-left: 5px

.pointB
    border-radius: 40px
    width: 28px
    border-width: 2px
    border-style: solid
    border-color: #e3ff70
    position: absolute
    right: 75px
    margin-top: -19px

.wrapper
    border: 1px solid $n3
    border-radius: 6px
    padding: 12px
    margin-bottom: 20px
    +dark
        border-color: $n6

.row
    display: flex
    justify-content: space-between
    margin-bottom: 5px
    height: 24px

.gray
    color: gray

.item
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center
    font-size: 14px
    .label
        font-weight: 400
        svg
            fill: #6F767E
            margin-left: 5px
            margin-top: -2px
    .value
        font-weight: 500

.foot
    margin-top: 14px
    padding-top: 0px
    border-top: 1px solid $n3
    +dark
        border-color: $n6
    .button
        width: 100%
        margin-top: 10px
        display: inline-flex
    .loader
        transform: scale(.8)
        filter: invert(1)
        margin: 0 18px 0 8px

.approve
    display: none
    &.active
        display: inline-flex


.mbAPY
    margin-bottom: 0px

.error
    color: $p3
    text-align: center
    margin-bottom: 6px
    margin-top: 12px

.collateral
    line-height: 0px
    svg
        fill: $p2


.successWrapper
    // margin-top: 30px
    text-align: center
    .icon
        margin-top: 30px
        display: flex
        justify-content: center
        svg
            fill: $p2
    .text
        margin-top: 24px
        font-size: 16px

.failed
    svg
        fill: $p3 !important

.newLimit
    svg
        fill: $n4
        margin-left: 5px
        margin-right: 5px

.mode
    color: $n4
    // font-weight: 600
    margin-left: 3px
    cursor: pointer
    font-size: 14px
    svg
        fill: $n4
    &:hover
        color: $n1
        svg
            fill: $n1

.tooltip
    svg
        fill: $shades1
    img
        margin-top: -5px
        margin-bottom: -5px

.wise
    border-radius: 50px
    width: 30px
    right: 35px
    border-width: 2px
    border-style: solid
    border-color: #73ffdb
    margin-right: -8px

.pointC
    // width: 30px
    width: 32px
    right: 35px

.button
    color: $n7
    font-size: 14px
    svg
        fill: $n7

.pointA
    // width: 30px
    width: 30px
    right: 35px

.supplyAPY
    display: flex
    text-align: center
    justify-content: center
    min-width: 55px
    .loader
        transform: scale(.77)
        margin: 11px 0px
        filter: invert(1)

.flex
    display: flex
