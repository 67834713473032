@import ../../../styles/helpers

.wrapper
    margin: 0 -12px
    overflow: auto

.table
    display: table
    width: 100%

.head
    text-align: center
    +m
        display: none !important

.row
    display: table-row
    +m
        margin-bottom: 24px

.tilt
    color: white !important

.col
    white-space: nowrap
    display: table-cell
    padding: 4px 6px 16px
    +caption2
    font-weight: 600
    vertical-align: bottom
    font-size: 14px
    // line-height: 24px
    color: $n4
    .tooltip
        fill: $n4

.foot
    margin-top: 12px
    text-align: center
    +m
        margin-top: 32px
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

