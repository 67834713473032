@import ../../styles/helpers

.input
	width: 100%
	height: 40px
	padding: 0 20px 0 44px
	background: $n2
	border-radius: 12px
	+base1-s
	color: $n7
	+dark
		background: $n6
		color: $n1
	&::placeholder
		color: $shades1

.truncate
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis

.focused
	white-space: normal
	text-overflow: clip
