@import ../../../styles/helpers

.wrapper
    margin: 0 -12px
    +m
        margin: 0

.table
    display: table
    width: 100%
    +m
        display: grid
        grid-template-columns: auto auto
        +s
            grid-template-columns: auto

.row
    display: table-row
    +m
        display: none
    &.active
        .col
            &:not(:first-child):not(:nth-child(2))
                display: none
            &:nth-child(2)
                &:after
                    right: 12px

.tooltip
    color: gray
    fill: gray
    margin-left: 5px
    margin-top: -1px

.col
    position: relative
    display: table-cell
    padding: 0 12px 16px
    vertical-align: middle
    font-size: 14px
    color: $n4
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
    &:first-child
        width: 0px
        font-size: 0
        padding: 0px
        &:after
            // left: 12px
    &:last-child
        &:after
            // right: 12px

.foot
    margin-top: 20px
    text-align: center
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

.placeholder
    min-height: 150px
    position: relative

.placeholderContent
    position: absolute
    top: 60%
    right: 50%
    bottom: 40%
    left: 50%
    margin-left: auto
    maring-right: auto