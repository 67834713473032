@import ../../styles/helpers

.product
    &:hover
        .preview:after,
        .control,
        .checkbox
            visibility: visible
            opacity: 1
        .empty
            background: rgba(131, 191, 110, 0.15)
            color: $p2
    &.active
        .checkbox
            visibility: visible
            opacity: 1
        .preview
            &:before
                border-color: $p1


.preview:after,
.control,
.checkbox
    opacity: 0
    visibility: hidden
    transition: all .2s

a
    color: $n2
    +m
        height: 26px
        overflow: hidden
    +s
        height: 26px
    +dark
        color: $n4
    &:hover
        color: $n1

.preview
    position: relative
    height: 200px
    margin-bottom: 12px
    border-radius: 12px
    overflow: hidden
    &:hover
        filter: sepia(1) contrast(1.4) brightness(1.3) hue-rotate(-210deg)
        box-shadow: 10px 10px 15px -15px rgba(0,0,0,0.82)
        transition: all .8s
        button
            opacity: 0.7 !important
        svg
            fill: $p1
            filter: brightness(0.4)
    &:after,
    &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        transition: all .8s
    &:after
        // background: rgba($n8, .3)
        filter: sepia(1) contrast(1.4) brightness(1.3) hue-rotate(-210deg)
    &:before
        z-index: 2
        border: 2px solid transparent
        border-radius: 12px
        pointer-events: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
    .checkbox
        position: absolute
        top: 12px
        left: 12px
        z-index: 3
        +t
            display: none
        .checkboxTick
            border-color: $p1

.control
    position: absolute
    top: 50%
    left: 50%
    z-index: 3
    transform: translate(-50%,-50%)

.line
    display: flex
    align-items: center
    justify-content: center

.title
    margin-right: auto
    font-size: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.price,
.empty
    flex-shrink: 0
    display: inline-block
    margin-left: 32px
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.price
    background: $s4
    font-size: 13px
    +dark
        color: $n7

.empty
    background: $n3
    font-size: 13px
    +dark
        background: $n6
        color: $n1

.date
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    color: $n4
    +m
        margin-top: 2px
    svg
        margin-right: 8px
        fill: $n4

.rating
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    +m
        margin-top: 4px
    svg
        margin-right: 8px
        fill: #FFC554
    span
        margin-left: 4px
        color: $n4

.ratingEmpty
    color: $n4
    svg
        fill: $n4

.dimmed
    opacity: 0.3 !important

.disabled
    &:hover
        filter: none !important

