@import ../../../styles/helpers


.fieldset
    margin-bottom: 1px
    .dropdown,
    .field
        margin-bottom: 12px

.label
    margin-bottom: 20px
    +caption1
    font-weight: 500
    color: $n4

.foot
    margin-top: 14px
    padding-top: 0px
    border-top: 1px solid $n3
    width: 100%
    +dark
        border-color: $n6
        .button
            width: 100%
            margin-top: 10px
            color: $n7
            display: inline-flex
            font-size: 15px
    .loader
        transform: scale(.8)
        margin: 0 18px 0 8px
        filter: invert(1)

.counter
    margin-bottom: 20px
    font-size: 30px
    font-weight: 600
    line-height: 1
    letter-spacing: -.03em
    .conversion
        color: $n4

.successWrapper
    // margin-top: 30px
    text-align: center
    position: relative
    // min-height: 320px
    .icon
        margin-top: 30px
        display: flex
        justify-content: center
        svg
            fill: $p2
    .text
        margin-top: 24px
        font-size: 16px

.stroker
    color: $n1 !important