@import ../../styles/helpers

.header
    position: fixed
    padding: 10px
    background: rgb(27 29 31 / 50%)
    background-color: rgb(12, 5, 18)
    backdrop-filter: blur(7px)
    top: 0
    left: 300px
    right: 0
    z-index: 15
    display: flex
    align-items: center
    padding: 18px 24px
    backdrop-filter: blur(3px)
    background-color: #00000099
    padding-left: 0px
    // background: $n1
    // box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3
    // background-color: var(--token-30c5159a-0571-42a9-94be-0aeb3b7837ae, rgb(12, 5, 18))
    // mask: linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 74.3701%, rgba(0, 0, 0, 0.318) 91.8919%, rgba(0, 0, 0, 0) 100%)
    +xl
        left: 96px
        height: 80px
    +m
        left: 0
        padding: 16px 24px 16px 16px
    +dark
        // background: $n7
        // box-shadow: inset 1px 0px 0px $n8, inset 0 0px 0px $n8
    &.expand
        +xl
            left: 285px
        +d
            left: 96px
        +m
            left: 0
            padding: 16px 24px 16px 16px
.burger
    display: none
    flex-direction: column
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    margin-right: auto
    +m
        display: flex
    &:before,
    &:after
        content: ""
        width: 16px
        height: 2px
        border-radius: 2px
        background: $n4
    &:before
        margin-bottom: 6px

.control
    display: flex
    align-items: center
    margin-left: auto
    +m
        margin-left: 0

.button
    height: 40px
    svg
        fill: $n7

.control .button,
.control > div,
.buttonSearch
    &:not(:last-child)
        margin-right: 24px
        +s
            margin-right: 12px

.buttonSearch
    display: none
    width: 48px
    height: 48px
    svg
        fill: $n4
        transition: fill .2s
    &.active
        svg
            fill: $n7
            +dark
                fill: $n1

.search
    +m
        visibility: hidden
        opacity: 0
        transition: all .2s
        &.visible
            visibility: visible
            opacity: 1

.btns
    display: flex
    align-items: center
    margin-left: auto
    +m
        margin-left: 0

.link
    svg
        fill: $n4
        margin-left: 0px
        margin-right: 10px
        +a
            margin-left: 0px
            margin-right: 5px
    margin-right: 24px
    +button1
    color: $n2
    +m
        margin-right: 12px
        height: 26px
        display: none
    +s
        margin-right: 12px
    +dark
        color: $n4
    &:hover
        color: $n1
        > svg
            fill: $n1

.hidler
    +x
        display: none

.connectWalletLabel
    .wallet
        +x
            display: none
.button
    color: $n7
    &:hover
        color: $n1
