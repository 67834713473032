@import ../../../styles/helpers

.nav
    display: flex
    margin-bottom: 32px
    padding: 8px
    gap: 1px
    background: $n2
    border-radius: 20px
    +m
        margin-bottom: 24px
    +dark
        background: $n8
        gap: 10px
    &:hover
        color: white

.item
    &.active
        background: $n1
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        +dark
            background: $n6
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)

.body
    min-height: 250px
    position: relative

.loaderContainer
    position: absolute
    display: flex
    inset: 0
    margin: auto
    height: fit-content
    justify-content: center
    .loader
        transform: scale(.8)
        margin: 10px 18px 0 8px