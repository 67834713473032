@import ../../../../styles/helpers

.pie
    height: 200px
    +m
        margin-bottom: -80px

.tooltip
    background-color: $n6
    border: 1px solid rgba(255, 255, 255, 0.12)
    border-radius: 8px
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000
    padding: 10px
    text-transform: capitalize
    font-size: 12px
    font-weight: 600
    color: #fff
    .label
        font-size: 12px
        font-weight: 500
        color: #fff

