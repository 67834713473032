.tooltip
    background-color: #272B30
    border-color: rgba(255, 255, 255, 0.12)
    border-radius: 8px
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000
    padding: 12px

.title,
.label
    font-size: 12
    font-weight: 500
    color: #fff

.item
    display: flex
    align-items: center
    gap: 4px
    text-transform: capitalize
