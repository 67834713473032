@import ../../styles/helpers

.background
    filter: brightness(0.3) contrast(0.7)
    height: 100px
    margin: -36px -40px 0
    +x
        margin: -32px -24px 0
    +d
        height: 85px
        margin-top: -16px
    +m
        height: 210px
        margin: -8px -16px 0
    img
        width: 100%
        height: 100%
        object-fit: cover

.card
    // max-width: 1200px
    margin: -64px auto 0
    +m
        margin-top: -188px

.control
    display: flex
    align-items: center
    margin-bottom: 32px
    +m
        flex-wrap: wrap

.nav
    display: flex
    margin-right: auto
    +m
        width: 100%
        margin: 0 0 16px
        display: none

.link
    padding: 12px 18px
    border-radius: 8px
    font-size: 16px
    color: $n4
    transition: all .2s
    +m
        flex-grow: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.dropdownBox
    +m
        flex-grow: 1
        display: none

.dropdownBox2
    display: none
    +m
        flex-grow: 1
        display: block

.dropdownBox2 div
    font-size: 16px

.filters
    margin-left: 16px
    +m
        margin-left: 12px

.products
    display: flex
    flex-wrap: wrap
    margin: -32px -12px 0
    +a
        display: block
        margin: 0

.product
    flex: 0 0 calc(25% - 24px)
    width: calc(25% - 24px)
    margin: 32px 12px 0
    +t
        flex: 0 0 calc(50% - 24px)
        width: calc(50% - 24px)
    +a
        width: 100%
        margin: 24px 0 0

.foot
    margin-top: 40px
    text-align: center
    +x
        margin-top: 32px
    +m
        margin-top: 24px
