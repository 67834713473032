@import ../../../styles/helpers

.list
    display: flex
    margin: 0 -6px
    +t
        margin: 0 -24px
        overflow: auto
        -webkit-overflow-scrolling: touch
        -ms-overflow-style: none
        scrollbar-width: none
        &::-webkit-scrollbar
            display: none
        &:before,
        &:after
            content: ""
            flex-shrink: 0
            width: 24px
            height: 1px
    +m
        margin: 0 -16px
        &:before,
        &:after
            width: 16px

.stables
    display: flex
    margin-left: 70px
    img
        width: 45px
        margin-left: -20px


.item
    display: block
    flex: 0 0 calc(25% - 12px)
    width: calc(25% - 12px)
    margin: 0 6px
    padding: 32px
    border-radius: 12px
    color: $n7
    border-style: solid
    border-width: 2px
    +t
        flex: 0 0 295px
        width: 295px
        margin: 0
        &:not(:last-child)
            margin-right: 12px
    +m
        flex: 0 0 285px
        width: 285px
    +dark
        color: $n3
    &:nth-child(1)
        +dark
            background: rgba(#A7AAF7, .15) !important
            border-color: rgba(#A7AAF7, .15)
    &:nth-child(4)
        +dark
            background: rgba(#A7AAF7, .15) !important
            border-color: rgba(#A7AAF7, .15)
    &:nth-child(2)
        +dark
            cursor: pointer
            background: rgba($s4, .1) !important
            border-color: rgba($s4, .1)
            &:hover
                background: rgba($s4, .15) !important
    &:nth-child(3)
        +dark
            cursor: pointer
            background: rgba($s4, .1) !important
            border-color: rgba($s4, .1)
            &:hover
                background: rgba($s4, .15) !important

.icon
    display: flex
    justify-content: center
    align-items: center
    width: 54px
    height: 54px
    margin-bottom: 12px
    border-radius: 50%
    background: $n7
    img
        padding: 4px
    svg
        margin-top: 0px
        margin-right: 0px
        fill: $n2
    +dark
        background: $n7
        svg
            fill: $n2

.line
    display: flex

.category
    display: flex
    align-items: center
    margin-bottom: 4px
    +title2
    color: $n5
    +dark
        color: $n3

.tooltip
    margin-left: 10px
    margin-top: -5px
    svg
        fill: $n5
        +dark
            fill: $n3

.counter
    margin-bottom: 4px
    font-size: 42px
    font-weight: 600
    line-height: 1
    letter-spacing: -.03em

.indicator
    display: inline-flex
    align-items: center
    border-radius: 8px
    padding: 4px
    background: $n1
    color: $n4
    +dark
        background: $n7
    span
        margin-left: 4px
        +caption2

.infoButton
    +a
        display: none

.infoButtonMobile
    display: none
    +a
        display: block

.active
    border-color: darkslategray !important
    background: rgba($s3, .15) !important
