@import ../../../styles/helpers

.row
    display: table-row
    cursor: pointer
    transition: background .2s
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1

.disabled
    opacity: .5
    pointer-events: none
    filter: grayscale(1)

.row
    &:hover
        .col
            &:after
                display: none

.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 16px 12px
    text-align: center
    white-space: nowrap
    color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        margin-bottom: -1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:nth-child(2)
        text-align: left
        padding-left: 0px
    &:first-child
        width: 0px !important
        text-align: left
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:last-child
        width: 400px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
            width: 100% !important
        &:after
            right: 12px
    &:not(:nth-child(2))
        width: 200px
        img
            margin-left: -10px
    // &:nth-child(3)
    //     +m
    //         display: none
    +t
        display: flex
        width: 100% !important
        padding-bottom: 0px


    // &:not(:nth-child(2))
    //     +t
    //         display: none

.tooltip
    svg
        margin-top: -3px
        margin-left: 5px
        fill: $shades1

.productItem
    display: flex
    align-items: center
    // cursor: pointer
    transition: color .2s
    +m
        align-items: stretch
    +dark
        color: $n1
    &:hover
        // color: $p1
    .price,
    .empty,
    .date
        display: none
    .price,
    .empty
        +t
            display: inline-block
    .date
        +t
            display: flex
            align-items: center
            margin-bottom: 8px
            +caption1
            color: $shades1
            svg
                margin-right: 8px
                fill: $shades1
.lbl
    font-size: 13px

.product
    max-width: 170px
    margin-bottom: 4px
    font-weight: 700
    +t
        max-width: 100%
        margin-bottom: 8px
        // padding-right: 44px
        +title2

.hidden
    display: none

.link
    +caption1
    color: $shades1
    white-space: nowrap
    // +t
    //     display: none

.linker
    +caption1
    color: #ffe19e
    white-space: nowrap
    // +t
    //     display: none

.icons
    +t
        img
            height: 25px
.details
    +t
        display: flex
        flex-direction: column
        justify-content: center

.price,
.empty
    flex-shrink: 0
    display: inline-block
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.control
    position: absolute
    top: 50%
    right: 12px
    transform: translateY(-50%)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none
.data
    font-family: inherit
    font-size: 13px
    filter: brightness(0.9) contrast(1.5)

.item
    display: flex
    justify-content: center
    width: 100%
    align-items: center
    +t
        justify-content: space-between
    .label
        display: none
        +t
            display: block
    .value
        width: 100%
        +s
            text-align: right

.hide
    +t
        display: none
