@import ../../../styles/helpers

.flexer
    display: flex
    align-items: center
    gap: 10px

.container
    background: $n7
    padding: 26px 24px 24px
    border-radius: 16px
    position: relative
    width: 50%

    img[alt~="success-background"]
        transform: scale(1)
        margin-top: 0
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin-left: auto
        margin-right: auto
        height: 100%
        pointer-events: none
