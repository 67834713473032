@import ../../../styles/helpers

.network
    position: relative
    +m
        position: static
    &.active
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.nopad
    padding: 0px !important

.modal
    max-width: 500px !important

.modal2
    max-width: 800px !important

.pointer
    color: gray
    cursor: pointer
    display: flex
    align-items: center
    &:hover
        color: inherit
    &.active
        color: inherit

.head
    div
        display: flex
        justify-content: center
        align-items: center
    img
        height: 30px
        margin-right: 12px
    display: flex
    align-items: center
    justify-content: space-between

.body
    position: absolute
    top: calc(100% + 18px)
    right: -20px
    width: 200px
    padding: 16px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    &.expand
        width: 350px
    +x
        top: calc(100% + 15px)
        right: 0
        border-radius: 12px
    +m
        top: 100%
        right: 1%
        left: 1%
        width: 96% !important
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    &:before
        content: ""
        position: absolute
        right: 90px
        bottom: 100%
        width: 20px
        height: 10px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        +x
            right: 13px
        +m
            right: 400px
        +t
            right: 150px
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")

    &:not(:last-child)
        margin-bottom: 12px
        padding-bottom: 12px
        border-bottom: 1px solid $n3
        +m
            margin-bottom: 8px
            padding-bottom: 8px
        +dark
            border-color: $n6

.item
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    height: 48px
    padding: 0 12px
    border-radius: 12px
    +base1-s
    color: $n4
    transition: all .2s
    // border: 1px solid $n6
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover
        background-color: $n6
        .chain
            color: $n7
        svg
            fill: $n7
        +dark
            .chain
                color: $n1
            svg
                fill: $n1

.separator
    height: 1px
    background: #3444
    margin: 11px 11px

.icon
    height: 25px
    margin-right: 12px

.svgIcon
    fill: $s4
    margin-left: 10px
    transition: transform 0.3s ease
    &.active
        transform: rotate(180deg)
    +a
        margin-left: -5px

.connected
    position: relative
    display: flex
    align-items: center
    &:before
        content: ""
        position: absolute
        left: -18px
        width: 12px
        height: 12px
        background: $p2
        border-radius: 50%

.chainIcon
    position: relative
    &:before
        content: ""
        position: absolute
        top: -2px
        right: 10px
        width: 13px
        height: 13px
        background: $p2
        border-radius: 50%
        z-index: 1

.chainLabel
    +a
        display: none

.lefty
    justify-content: flex-start
    &:hover
        color: $n1