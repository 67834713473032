@import ../../../../styles/helpers

.head
    +m
        flex-wrap: wrap
        .title
            width: 100%
            flex-grow: 1
            margin: 0 0 16px

.lobber
    width: 100%
    margin: auto
    display: flex
    justify-content: center
    align-items: center
    height: 77px

.button
    color: $n7
    // margin-left: 16px
    +m
        flex-grow: 1

.wrapper
    margin: 0 -24px
    background: $n1
    +m
        margin: 0 -16px
    +dark
        background: transparent

.table
    display: table
    width: 100%
    +t
        display: block

.outer
    max-width: 460px

.closed
    max-width: 460px

.row
    display: table-row
    cursor: pointer
    height: 88px
    &:hover
        background: #354b5087
        button
            background: #354b5087
            box-shadow: 0 0 0 2px #777 inset
        .invoice
            color: $n2
    +t
        display: flex
        height: 100%
        flex-direction: column
        padding: 16px 24px
    +m
        padding: 16px
    &:first-child
        +t
            display: none
        .col
            padding-top: 8px
            padding-bottom: 16px
            +caption2
            font-weight: 500
            color: $n4
            background: rgba($n3, .35)

    &:nth-child(2n)
        +t
            background: rgba($n3, .35)
            +dark
                background: rgba($n6, .5)
        .col
            background: rgba($n3, .35)
            +t
                background: none
            +dark
                background: rgba($n6, .5)
                +t
                    background: none


.striked
    text-decoration: line-through

.strikedGray
    text-decoration: line-through
    filter: grayscale(1)

.col
    display: table-cell
    padding: 10px 16px
    vertical-align: middle
    +base2
    +t
        display: flex
        align-items: center
        padding: 0
    &:first-child
        padding-left: 24px
        padding-right: 0px
        width: 77px
        +t
            display: none
    &:last-child
        padding-right: 24px
        text-align: center
        +t
            padding-right: 0
    &:not(:last-child)
        +t
            margin-bottom: 12px

.colmobile
    display: none
    +t
        display: table-cell
        vertical-align: middle
        margin-bottom: 16px
    .invoice
        display: none
        +t
            display: block !important
            margin-top: 8px
            font-size: 14px
            color: $n4
            font-weight: 500

.holduris
    display: flex
    align-items: center
    .icon
        background: #f4772e
        border-radius: 20px
        margin-right: 15px
        margin-top: 0px

.hider
    visibility: hidden
    position: absolute

.holderis
    display: flex
    align-items: center
    justify-content: center

.icon.active
    background: #1bc943

.icon.closed
    background: #999999

.icon.pending
    background: #bd67f7


.liner
    margin-bottom: 7px
    margin-top: 7px

.static
    display: flex
    justify-content: space-between

.stat
    content: ""
    width: 10px
    height: 10px
    background: #1bc943
    margin-bottom: -10px
    border-radius: 50%
    top: 2px
    position: relative

.statname
    margin-left: 15px

.status
    flex-shrink: 0
    white-space: nowrap
    font-size: 13px
    line-height: 23px
    font-weight: 600
    color: $n7

.productor
    color: #1bc943
    font-size: 14px

.details
    display: none
    +t
        display: block
        margin-right: auto
        padding-right: 16px

.product
    color: $n5
    +dark
        color: $n3
        &.hasrewards
            color: #1bc943
        &.norewards
            color: $n4
        &.lastreward
            color: #f4772e

.active
    .col
        background: #395b66 !important
    .invoice
        color: white

.invoice
    margin-top: 0px
    +caption1
    font-weight: 500
    color: $n4
    +t
        display: none

.negative
    color: $p3

.foot
    padding: 12px 0
    text-align: center
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

.label
    display: none
    margin-right: auto
    +base2
    color: $n4
    +t
        display: block