@import ../../../styles/helpers

.chart
    width: auto
    height: 274px
    margin-bottom: -10px

.recharts-cartesian-axis
    width: 100px

.recharts-cartesian-axis-tick-value
    white-space: nowrap
    width: 100px
