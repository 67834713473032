@import ../../styles/helpers

.sidebar
    position: fixed
    top: 0
    left: 0
    bottom: 0
    display: flex
    flex-direction: column
    padding: 24px
    background: $n1
    overflow: auto
    -webkit-overflow-scrolling: touch
    -ms-overflow-style: none
    scrollbar-width: none
    width: 300px
    padding-bottom: -40px !important
    margin-bottom: -40px !important
    align-items: stretch
    z-index: 14
    &::-webkit-scrollbar
        display: none
    +xl
        width: 96px
        padding-bottom: -40px !important
        margin-bottom: -40px !important
        align-items: center
    +m
        width: 100%
        align-items: stretch
    +dark
        // background: $n7
        // background-color: var(--token-30c5159a-0571-42a9-94be-0aeb3b7837ae, rgb(12, 5, 18))
        background: #000
        // mask: linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 74.3701%, rgba(0, 0, 0, 0.318) 91.8919%, rgba(0, 0, 0, 0) 100%)
    &.expand
        +xl
            width: 300px
            .loger
                +xl
                    display: block
            .expandToggle
                svg
                    fill: $n7
                    &:first-child
                        display: none
                    &:nth-child(2)
                        fill: $n4
                        display: inline-block
    &.active
        +d
            width: 300px
            box-shadow: 4px 0 32px rgba($n8, .05)
            .loger
                +xl
                    display: none
            .toggle
                position: absolute
                top: 16px
                right: 16px
                svg
                    fill: $n7
                    &:first-child
                        display: none
                    &:nth-child(2)
                        fill: $n4
                        display: inline-block
    &.active,
    &.expand
        +xl
            align-items: stretch
            .item,
            .link
                width: 100%
                font-size: 15px
                svg
                    margin-right: 12px
            .counter
                display: block
        +m
            width: 100%

.logo
    display: flex
    margin-bottom: 32px
    filter: hue-rotate(-45deg)
    +x
        margin-bottom: 24px
        margin-top: 10px
        margin-left: 0px
    +m
        margin-left: auto

.audit
    width: 24px
    margin-right: 12px
    &:hover
        filter: brightness(2)

.iconer
    width: 23px
    margin-left: 0px
    margin-right: 14px

.active
    .iconer
        filter: brightness(2)

.icon
    width: 45px
    margin-left: 3px
    &:hover
        filter: brightness(2)

.loger
    width: 190px
    margin-top: 8px
    margin-left: 12px
    +xl
        display: none

.menu
    display: flex
    flex-direction: column
    margin-bottom: auto

.item,
.dropdown
    margin-bottom: 8px

.item,
.link
    display: flex
    align-items: center
    width: 100%
    font-size: 15px
    height: 48px
    padding: 0 12px
    border-radius: 12px
    font-weight: 600
    white-space: nowrap
    color: $n4
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: background .2s, box-shadow .2s, color .2s
    +xl
        width: 48px
        font-size: 0
    +m
        width: 100%
        font-size: 15px
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
        +xl
            margin-right: 0
        +m
            margin-right: 12px

    &:hover,
    &:hover > svg
        filter: brightness(2)
    &.active
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &.active
        background: $n3
        box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)

.toggle
    display: none
    width: 48px
    height: 48px
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    +d
        display: inline-block
    +m
        display: none
    svg
        fill: $n4
        &:nth-child(2)
            display: none

.expandToggle
    display: none
    width: 100%
    height: 48px
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    +xl
        display: inline-block
    +d
        display: none
    svg
        fill: $n4
        &:nth-child(2)
            display: none
    h1
        display: none

.close
    display: none
    +m
        display: block
        position: absolute
        top: 16px
        left: 16px
        width: 48px
        height: 48px
        svg
            fill: $n7
            +dark
                fill: $n1

.foot
    margin-top: 24px
    padding-top: 18px
    border-top: 2px solid $n2
    +d
        margin-top: 12px
        padding-top: 12px
    +dark
        border-color: $n6

.theme
    margin-top: 16px

.counter
    flex-shrink: 0
    min-width: 24px
    margin-left: auto
    border-radius: 6px
    background: $s2
    text-align: center
    line-height: 24px
    color: $n7
    +xl
        display: none
    +m
        display: block

.overlay
    +d
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 12
        background: rgba($n2, .8)
        visibility: hidden
        opacity: 0
        transition: all .2s
        +dark
            background: rgba($n6, .9)
        &.active
            visibility: visible
            opacity: 1
    +m
        display: none

.titler h1
    line-height: 36px
    display: none
    color: white
    font-smooth: always
    filter: brightness(0.9) hue-rotate(55deg) grayscale(1)
    margin-left: 8px
    margin-top: 2px
    margin-bottom: -2px
    &:hover
        filter: brightness(1) hue-rotate(55deg) grayscale(0)
    +xl
        font-size: 32px
    +m
        font-size: 24px
    &::after
        content: ""
        display: inline-block
        width: 24px
        transform: scale(0.77)
        height: 24px
        margin-top: -2px
        vertical-align: top
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' d='M2.031 12c0-.134-.027-.268-.079-.393L1.231 9.86C1.079 9.492 1 9.097 1 8.699s.078-.793.231-1.161.376-.703.658-.984.616-.505.984-.657l1.744-.722c.253-.104.454-.305.559-.557l.723-1.744c.308-.743.898-1.333 1.641-1.641s1.578-.308 2.321 0l1.743.722c.253.104.537.104.79-.001l.002-.001 1.745-.72c.743-.307 1.578-.307 2.321 0s1.333.898 1.641 1.641l.705 1.703.018.041a1.03 1.03 0 0 0 .558.559l1.745.723c.743.308 1.334.898 1.641 1.641s.308 1.578 0 2.321l-.722 1.744c-.053.127-.079.262-.079.396a1.03 1.03 0 0 0 .079.395l.722 1.744c.308.743.308 1.578 0 2.321s-.898 1.334-1.641 1.641l-1.745.723c-.253.105-.453.306-.558.559l-.018.041-.705 1.703a3.03 3.03 0 0 1-1.641 1.641c-.743.308-1.578.308-2.321 0l-1.745-.72-.002-.001c-.253-.105-.537-.105-.79-.001l-1.743.722a3.03 3.03 0 0 1-2.321 0c-.743-.308-1.333-.898-1.641-1.641l-.723-1.744c-.105-.252-.307-.453-.559-.557l-1.744-.722c-.368-.152-.702-.376-.984-.657s-.505-.616-.658-.984S1 15.7 1 15.301s.079-.793.231-1.161l.721-1.747c.052-.126.079-.26.079-.393zm14.176-1.793a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4.5-4.5z' fill='%2300a656'/%3E%3C/svg%3E")

.expand
    .titler h1
        display: block

.discorder
    width: 24px
    margin-right: 12px
    transform: scale(1.4)

.switcher
    display: flex
    align-items: center
    // flex-direction: row-reverse
    gap: 10px
    margin-bottom: 16px
    cursor: pointer
    +xl
        margin-bottom: 12px
    +m
        margin-bottom: 16px
    svg
        fill: $n4
        margin-right: 12px
        transition: fill .2s
    &:hover
        svg
            filter: brightness(2)

.modal
    max-width: 900px