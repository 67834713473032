@import ../../../styles/helpers

.items
    margin-right: -23px
    margin-left: -23px
    margin-bottom: -23px
    margin-top: -13px

.item
    display: flex
    align-items: center
    color: $n5
    padding-top: 19px
    cursor: pointer
    border-radius: 10px
    padding-left: 18px
    padding-right: 18px
    font-size: 14px
    &:hover
        background: $n2
        +dark
            background: $n6
        .borrow
            background: darken($p1, 10)
    +dark
        color: $n3
    &:last-child
        padding-bottom: 23px
    &:not(:last-child)
        // margin-bottom: 20px
        padding-bottom: 18px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6

.link
    fill: white
    margin-left: 10px

.currency
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 32px
    height: 32px
    margin-right: 12px
    border-radius: 50%
    background: $n3
    +dark
        background: none
        filter: brightness(0.8) contrast(1.3)

.price
    margin-left: auto
    padding-left: 20px
    color: $n7
    +dark
        color: $n1
