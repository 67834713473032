@import ../../../styles/helpers

.head
    +m
        flex-wrap: wrap
        .title
            width: 100%
            flex-grow: 1
            margin: 0 0 16px
    .title
        margin-right: initial

.button
    color: $n7
    margin-left: 16px
    font-size: 14px
    +m
        flex-grow: 1

.outer
    max-width: 890px

.filters
    margin-left: 10px

.head
    +t
        display: none !important

.header
    position: sticky
    top: 0px
    background: $n7
    color: $n4
    z-index: 1
    backdrop-filter: blur(3px)
    background-color: rgba(12, 5, 18, 0.6)
    .col
        vertical-align: middle
        &:hover
            color: $n1
            cursor: default
    +m
        display: none !important

.loader
    display: flex
    gap: 20px
    height: 0px
    justify-content: center
    position: relative

.modal
    min-width: 500px

.nopad
    padding: 0 !important

.loader
    position: absolute
    margin: auto
    width: 100%
    top: 45%
    text-align: center

.pagination
    display: flex
    gap: 10px
    height: 77px
    justify-content: center
    padding-top: 22px
    margin: auto
    z-index: 10
    width: 650px
    position: relative
    backdrop-filter: blur(3px)
    bottom: 0px
    li
        border-width: 1px
        border-color: $n4
        border-style: solid
        border-radius: 50px
        width: 35px
        height: 35px
        justify-content: center
        align-content: center
        text-align: center
        color: $n4
        &:hover
            cursor: pointer
            border-color: $n1
            color: $n1
        &.activepage
            color: $n1
            border-color: $n1
        a
            display: flex
            justify-content: center
            align-items: center
            width: 100%
            height: 100%

.pagination li.disabledbutton
    cursor: default
    color: $n4
    border-color: $n4
    opacity: 0.3
    a:hover
        border-color: $n4
        color: $n4

.dropdown
    margin-right: 10px
    margin-left: 10px

.wrapper
    margin: 0 -24px
    background: $n1
    height: calc(100vh - 245px)
    overflow-y: auto
    margin-bottom: -77px
    +m
        margin: 0 -16px
    +dark
        background: transparent

.wrapper.fullscreen
    height: calc(100vh - 90px)

.table
    display: table
    margin-bottom: 77px
    width: 100%
    +t
        display: block

.row
    display: table-row
    +t
        display: flex
        flex-direction: column
        padding: 16px 24px
    +m
        padding: 16px
    &:first-child
        +t
            display: none
        .col
            padding-top: 8px
            padding-bottom: 16px
            +caption1
            font-weight: 600
            color: $n4
    &:nth-child(2n)
        +t
            background: rgba($n3, .35)
            +dark
                background: rgba($n6, .5)
        .col
            background: rgba($n3, .35)
            +t
                background: none
            +dark
                background: rgba($n6, .5)
                +t
                    background: none

.col
    display: table-cell
    padding: 10px 16px
    +base2
    +t
        display: flex
        align-items: center
        padding: 0
    &:first-child
        padding-left: 24px
        +t
            padding-left: 0
    &:nth-child(2)
        +t
            align-items: flex-start
            order: -1
            padding-bottom: 12px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6
    &:nth-child(3)
        +t
            display: none
    &:last-child
        padding-right: 24px
        text-align: center
        +t
            padding-right: 0
    &:not(:last-child)
        +t
            margin-bottom: 12px

.status
    flex-shrink: 0
    white-space: nowrap
    font-size: 14px
    line-height: 28px
    font-weight: 600
    color: $n7

.details
    display: none
    +t
        display: block
        margin-right: auto
        padding-right: 16px

.product
    color: $n5
    +dark
        color: $n3

.invoice
    margin-top: 4px
    +caption2
    font-weight: 500
    color: $n4

.negative
    color: $p3

.foot
    padding: 12px 0
    text-align: center
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

.label
    display: none
    margin-right: auto
    +base2
    color: $n4
    +t
        display: block