@import ../../../styles/helpers

.overview
    display: flex
    +t
        display: block

.list
    display: flex
    justify-content: space-between
    flex-grow: 1
    margin-right: 0px
    +d
        margin-right: 0px
    +t
        display: block
        margin: 0 0 0px

.card
    padding-top: 20px
    padding-bottom: 20px

.item
    display: flex
    padding: 0 64px
    color: $n7
    position: relative
    gap: 8px
    padding-left: 20px
    flex: 1
    +w
        padding: 0 48px
    +x
        padding: 0 32px
    +t
        padding: 0
        border: none
    +dark
        border-color: $n6
        color: $n1
    &:first-child
        padding-left: 0px
        +t
            padding-left: 0
    &:not(:last-child)
        +t
            margin-bottom: 24px
            padding-bottom: 24px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6
    &:not(:first-child)
        &::before
            content: ''
            position: absolute
            left: -6px
            height: 100%
            width: 1px
            background-color: $n6
            +t
                display: none

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-top: 6px
    margin-right: 24px
    border-radius: 50%
    svg
        fill: $n7

.label
    display: flex
    white-space: nowrap
    align-items: center
    margin-bottom: 8px
    font-size: 15px
    color: $n4

.tooltip
    svg
        fill: $n4

.counter
    font-size: 32px
    font-weight: 600
    line-height: 1
    letter-spacing: -.03em
    +x
        font-size: 40px

.button
    flex-shrink: 0
    +m
        width: 100%

.outer
    max-width: 560px

.pointsFarmingIcons
    display: flex
    gap: 6px

.pointsFarmingTooltip
    height: 36px

.purple,
.blue
    height: 100%
    aspect-ratio: square

.blue
    border: 2px solid #1ccce4
    border-radius: 23px

