[class^="status"]
    display: inline-block
    padding: 0 8px
    // background: rgba($dark3, .1)
    border-radius: 6px
    font-size: 12px
    line-height: 26px
    font-weight: 700
    // color: $dark2

.status-green
    background: #EAFAE5
    color: $p2
    +dark-common
        background: rgba(#83BF6E, .15)

.status-green-dark
    background: $s7
    color: $n7
    font-size: 13px

.status-red
    background: #FFE7E4
    color: $p3
    +dark-common
        background: rgba($p3, .15)

.status-red-dark
    background: $s1
    color: $n1

.status-purple
    background: #f99191
    color: $p4

.status-purple-dark
    background: #f99191
    color: $n7

.status-blue
    background: $s6
    color: $p1

.status-yellow
    background: $s5
    color: $n7

.status-gray
    background: #848484
    color: $n1
