@import ../../../styles/helpers

.card
    margin-bottom: 8px

.apys
    display: flex
    flex-direction: column
    gap: 20px
    padding-bottom: 10px
    padding-top: 10px

.col
    display: flex
    justify-content: space-between

.button
    margin-bottom: 6px !important

.title,
.label
    font-size: 12
    font-weight: 500
    color: #fff

.item
    display: flex
    align-items: center
    gap: 4px
    text-transform: capitalize
