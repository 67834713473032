@import ../../../styles/helpers

.head
    +m
        display: block

.title
    +m
        margin-bottom: 16px

.chart
    width: auto
    height: 250px
    margin-left: -35px
    margin-bottom: -10px

.loaderContainer
    position: absolute
    display: flex
    inset: 0
    margin: auto
    height: fit-content
    justify-content: center
    .loader
        transform: scale(.8)
        margin: 10px 18px 0 8px