@import ../../styles/helpers

.row
    display: flex
    +t
        display: block

.col
    flex: 0 0 calc( 50% - 4px)
    width: calc( 50% - 4px)
    &:not(:last-child)
        margin-right: 8px
    +t
        width: 100%
        margin-bottom: 8px
        padding-right: 0
    +m
        width: 100%
        display: none
        &.active
            display: block

.card
    &:not(:last-child)
        margin-bottom: 8px

.nav
    display: none
    +m
        display: flex
        margin-left: auto
        margin-bottom: 20px
        .button
            flex: 1
            padding: 8px 16px
            border-radius: 8px
            +base1-s
            color: $n4
            transition: all .2s
            &:hover,
            &.active
                color: $n7
                +dark
                    color: $n1
            &.active
                background: $n3
                +dark
                    background: $n6
            &:not(:last-child)
                margin-right: 0
