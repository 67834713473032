@import ../../../../styles/helpers

.control
    display: flex
    align-items: center

.viewButton
    background-color: #5c9cde

.buttonHolder
    display: flex
    justify-content: center
    align-content: center
    gap: 10px
    +t
        margin-top: 16px
        width: 100%
        display: block
    button
        height: 40px
        color: $n7
        width: 160px
        +t
            width: 100%

.disabled
    filter: grayscale(1)

.openOuter
    max-width: 896px
    & > div
        +m
            padding: 26px 16px 16px

.viewOuter
    max-width: 472px
    & > div
        +m
            padding: 26px 16px 16px
