
// The icon specifics
// Variables
$green: #4cc93f
$borderSize: 20px

.imageHolder
    min-height: 150px

.backImage
    transform: scale(0.80)
    margin-bottom: -80px
    margin-top: -20px
    opacity: 0.2
    position: absolute
    left: 0px
    top: 30px

// Structure
.successIcon
    transform: scale(0.40)
    margin-top: -200px
    margin-bottom: -50px
    display: inline-block
    width: 10em
    height: 10em
    // transform: scale(0.5)
    font-size: $borderSize
    background: #1b1d1f
    border-radius: 50%
    border: 10px solid lighten($green, 20%)
    position: relative
    overflow: hidden
    transform-origin: center
    // animation: showSuccess 180ms ease-in-out
    // transform: scale(1)

.tip,
.long
    display: block
    position: absolute
    height: 1em
    margin-left: 12px
    margin-top: 6px
    background-color: lighten($green, 20%)
    border-radius: 1em

.tip
    width: 2.8em
    top: 4.3em
    left: 1.4em
    transform: rotate(45deg)
    animation: tipInPlace 300ms ease-in-out
    animation-fill-mode: forwards
    animation-delay: 180ms
    visibility: hidden

.long
    width: 4.4em
    transform: rotate(-45deg)
    top: 3.80em
    left: 2.55em
    animation: longInPlace 140ms ease-in-out
    animation-fill-mode: forwards
    visibility: hidden
    animation-delay: 300ms + 140ms

@keyframes showSuccess
    from
        transform: scale(0)
    to
        transform: scale(1)

@keyframes tipInPlace
    from
        width: 0em
        top: 3.5em
        left: 2em
    to
        width: 2.8em
        top: 4.3em
        left: 1.4em
        visibility: visible

@keyframes longInPlace
    from
        width: 0em
        top: 5.1em
        left: 3.2em
    to
        width: 4.4em
        top: 3.80em
        left: 2.55em
        visibility: visible
