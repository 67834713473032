@import ../../../../../../styles/helpers

.parameter
    display: flex
    align-items: center
    padding: 16px 0
    height: 60px
    border-bottom: 1px solid rgba($shades4, .1)
    +dark
        border-color: rgba($shades2, .15)

.title
    margin-right: auto
    padding-right: 12px
    color: $n4

.tooltip
    position: relative
    top: -1px
    svg
        fill: $n4

.red
    color: $p3

.yes,
.no
    display: inline-block
    padding: 2px 8px
    border-radius: 6px
    white-space: nowrap
    +dark
        color: $n7

.yes
    background: $s4 !important

.green
    color:#1bc943

.no
    background: $s1

.content
    text-align: right
