@import ../../styles/helpers

.box
    display: flex
    font-size: 14px

.avatar
    position: relative
    flex-shrink: 0
    width: 42px
    height: 42px
    margin-right: 10px
    border-radius: 100%
    outline: 5px solid $n7
    box-sizing: border-box
    z-index: 2
    +m
        margin-right: 12px
    & > img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.details
    flex-grow: 1
    min-width: 0

.line
    // display: flex
    align-items: center
    margin-bottom: 4px

.author
    margin-right: auto
    font-weight: 700
    color: $n4

.time
    margin-left: 24px
    +caption1
    color: $shades1

.comment
    color: $n5
    +dark
        color: $n1

.control
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1

.answer
    position: relative
    margin-top: 28px
    &:before
        content: ""
        position: absolute
        top: -42px
        left: -35px
        width: 28px
        height: 62px
        border-bottom-left-radius: 12px
        border-width: 0 0 2px 2px
        border-style: solid
        border-color: $n3
        z-index: 1
        +m
            left: -37px
        +dark
            border-color: $n5