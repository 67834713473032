@import ../../../styles/helpers

.card
    margin-bottom: 16px

.wrapper
    margin: 0 -12px
    +t
        margin: 0

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    +t
        display: none

.col
    text-align: center
    position: relative
    display: table-cell
    white-space: nowrap
    padding: 0 12px 16px
    vertical-align: middle
    font-size: 14px
    color: $n4
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        margin-bottom: -1px
        +dark
            background: $n6
    &:nth-child(2)
        text-align: left
        &::after
            left: 12px
            margin-bottom: -1px
    &:first-child
        width: 0px
        position: absolute
        display: none
        text-align: left
        font-size: 0
        &:after
            left: 0px
    &:last-child
        &:after
            right: 12px

.foot
    margin-top: 20px
    text-align: center
    +t
        margin-top: 0
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

.emptyPlaceholder
    color: $n5
    display: table-caption
    caption-side: bottom

