@import ../../styles/helpers

.page
    width: 100vw !important
    height: 100vh !important

.thumbHorizontal, .thumbVertical
    background-color: $n4 !important

.wrapper
    width: 100% !important
    .thumbHorizontal, .thumbVertical,
    .trackHorizontal, .trackVertical
        opacity: 0 !important
        transition: opacity 0.3s !important
    &:hover
        .thumbHorizontal, .thumbVertical,
        .trackHorizontal, .trackVertical
            opacity: 1 !important

.componentContent 
    > div:first-child
        padding-right: 12px !important
        +m
            padding-left: 6px !important
            padding-right: 6px !important

.componentVertical
    right: -12px !important

.componentHorizontal
    bottom: -12px !important
