@import ../../../styles/helpers

.netAPY
    margin-right: 10px
    opacity: .8
    height: 42px
    &.active
        background: none
        box-shadow: 0 0 0 2px $n7 inset
        opacity: 0.7
        &:hover
            opacity: 1
        svg
            fill: $n7
        +dark-common
        box-shadow: 0 0 0 2px $n3 inset
        svg
            fill: $n1

.flexHolder
    display: flex
    justify-content: center
    align-items: center
    span
        // width: 100px

.m0
    margin-left: 0px

.textGreen
    color: $s4

.textPurple
    color: $s2

.textBlue
    color: $s3

.bigger
    font-size: 15px !important
