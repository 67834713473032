@import ../../../styles/helpers

.text
    margin-right: -23px
    margin-left: -23px
    margin-bottom: -23px
    margin-top: -13px
    padding-left: 23px
    padding-right: 23px
    padding-bottom: 18px
    height: 200px

.card
    margin-top: 10px
