@import ../../../styles/helpers

.card
    margin-bottom: 8px

.chart
    width: auto
    height: 230px
    z-index: 1
    position: relative
    +x
        margin-left: -12px
    +t
        max-width: 310px
        margin: 0 auto
    +m
        padding-left: 10px

.legend
    display: flex
    justify-content: space-between
    margin-top: 32px

.total
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    position: absolute
    width: 100%
    height: 100%
    z-index: 0
    top: -3px
    left: 3px
    padding-top: 20px
    font-size: 32px

.indicator
    display: flex
    align-items: center
    +caption2
    font-weight: 700

.color
    flex-shrink: 0
    width: 16px
    height: 16px
    margin-right: 8px
    border-radius: 4px

.tooltip
    background-color: #272B30
    border-color: rgba(255, 255, 255, 0.12)
    border-radius: 8px
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000
    padding: 12px

.title,
.label
    font-size: 12
    font-weight: 500
    color: #fff

.item
    display: flex
    align-items: center
    gap: 4px
    text-transform: capitalize