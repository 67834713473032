@import ../../styles/helpers

.container
    position: relative
    filter: brightness(0.8) contrast(1.4)

.logo
    height: 100%

.subLogo
    position: absolute
    height: 55%
    width: 55%
    border-radius: 50%
    border: 1px solid #1A1D1F
    right: -5px
    bottom: -3px
    background-position: center
    background-size: cover
