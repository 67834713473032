@import ../../../../styles/helpers

.market
    margin: 0 -12px
    +t
        margin: 0

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    +t
        display: block
        margin-bottom: 24px

.col
    font-size: 14px
    position: relative
    display: table-cell
    padding: 0 12px 16px
    vertical-align: middle
    color: $n4
    +t
        display: block
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 56px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:nth-child(2)
        width: 200px
        +t
            display: none
    &:last-child
        &:after
            right: 12px

.line
    display: flex
    align-items: center
    +t
        display: block

.info
    margin-right: auto
    +t
        display: none

.indicators
    display: flex
    flex-wrap: wrap
    margin: -8px 0 0 -24px
    +w
        margin-left: -12px
    +t
        margin-left: -24px

.legend
    position: relative
    margin: 8px 0 0 24px
    padding-left: 24px
    +w
        margin-left: 12px
    +d
        padding-left: 18px
    +t
        margin-left: 24px
        padding-left: 24px

.color
    position: absolute
    top: 2px
    left: 0
    width: 12px
    height: 12px
    border-radius: 4px

.foot
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 24px
    margin-right: 24px
    button
        width: 280px
    .button-shorter
        width: 200px !important